import React from 'react';
import { CategoriesGallery } from '@mshops-components-library/categories-gallery';
import { useRenderContext } from '../../pages/home/context';

const Wrapper = (props) => {
  const { isEshops, shopType } = useRenderContext();
  return (
    <CategoriesGallery
      {...props}
      isEshops={isEshops}
      eshopType={shopType}
    />
  );
};

export default Wrapper;
